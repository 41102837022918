<script setup lang="ts">
import ItemSelectorWithFilter from '@/components/ItemSelectorWithFilter.vue'
import { useAppStore } from '@/stores/app'
import { ComponentInProjectListItemData } from '@/vuex/components_in_project/types'
import { AnalyticsResultsForComponentResultIds, ComponentInProject } from '@aedifion.io/aedifion-api'
import { useAnalyticsApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { whitelabelColor } from '@/filters/colors'
import { showErrorNotification } from '@/utils/helpers/notifications'
import { resetStoreState } from '@/vuex/analysis_instances/state'
import { useRoute } from 'vue-router'
import texts from '@theme/texts'
import vuexStore from '@/vuex'

interface Props {
  componentInProject: ComponentInProjectListItemData | ComponentInProject,
  isEditMode: boolean,
  isReadOnly?: boolean,
  instanceId?: number,
  showLinkToSelectedResult?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
  isReadOnly: false,
  showLinkToSelectedResult: false,
})

const selectedAnalysisResultId = defineModel<string>()

const { locale, t } = useI18n()

const appStore = useAppStore()
const analyticsApiStore = useAnalyticsApiStore()

const loading = ref(false)
const search = ref('')
const analysisResults = ref<AnalyticsResultsForComponentResultIds[]>([])

const selectedAnalysisResult = computed<AnalyticsResultsForComponentResultIds|string|null>(() => {
  const selectedAnalysisResultIdValue = analysisResults.value?.findIndex((analysisResult) => {
    return analysisResult.result_id === selectedAnalysisResultId.value
  })

  if (selectedAnalysisResultIdValue === -1) {
    if (selectedAnalysisResultId.value) {
      return selectedAnalysisResultId.value
    }

    return null
  }

  return analysisResults.value?.[selectedAnalysisResultIdValue]
})

const selectedAnalysisResultTitle = computed<string>(() => {
  if (typeof selectedAnalysisResult.value !== 'string' && selectedAnalysisResult.value?.display_name) {
    return selectedAnalysisResult.value.display_name
  }

  return selectedAnalysisResultId.value ?? ''
})

const route = useRoute()

const currentInstanceResultDisplayName = computed(() => {
  return vuexStore.state.analysis_instances.analysisResult?.display_name ?? ''
})

async function fetchAnalysisResults () {
  loading.value = true
  try {
    if (props.componentInProject.id) {
      const payload = {
        projectId: appStore.projectId,
        projectComponentId: props.componentInProject.id,
        language: locale.value,
      }
      const instanceId = route.query.instance as string ?? null

      if (instanceId === null) {
        // When instanceId is null, it means we are in the Checklist view and we should only fetch the latest results
        analysisResults.value = (await analyticsApiStore.getProjectComponentResults(payload)).result_ids ?? []
      } else {
        // When instanceId is not null, it means we are in the Analysis view and we should fetch the results for the selected instance
        const instanceResults = await analyticsApiStore.getInstanceResults({
          projectId: appStore.projectId,
          instanceId: Number(instanceId),
        })

        analysisResults.value = instanceResults.map((instanceResult) => ({
          display_name: currentInstanceResultDisplayName.value,
          result_id: instanceResult.result_id,
          end: instanceResult.input_parameters.end,
          start: instanceResult.input_parameters.start,
          signal_color: instanceResult.signal_color,
          // @ts-expect-error the types are not correct, the api returns interpretation instead of interpretations
          interpretation: instanceResult.interpretation,
          instance_id: Number(instanceId),
        }))
      }
    }
  } catch (error) {
    showErrorNotification(`${
      t('notifications.errors.fetch', {
        resource: t('notifications.resources.analysis_results'),
        supportEmail: texts.emailSupport,
      })
    }`)
    reportError(error)
  } finally {
    loading.value = false
  }
}

function onSelectAnalysisResult (analysisResult: AnalyticsResultsForComponentResultIds | null) {
  if (analysisResult === null) {
    selectedAnalysisResultId.value = undefined
    return
  }

  selectedAnalysisResultId.value = analysisResult.result_id
}

function onPasteAnalysisId (pastedAnalysisId: string) {
  if (typeof pastedAnalysisId === 'string' && pastedAnalysisId.length > 0) {
    selectedAnalysisResultId.value = pastedAnalysisId
  }
}

watch(() => props.componentInProject.id, async (value) => {
  selectedAnalysisResultId.value = undefined
  resetStoreState(vuexStore.state.analysis_instances)

  if (value === undefined) {
    analysisResults.value = []
    return
  }

  await fetchAnalysisResults()
})

fetchAnalysisResults()
</script>

<template>
  <ItemSelectorWithFilter
    v-model="search"
    data-test-id="analysis-result-selector"
    :filter-fields="['result_id', 'display_name', 'interpretation']"
    :is-edit-mode="isEditMode"
    :is-read-only="isReadOnly"
    item-locale-key="notifications.resources.analysis_result"
    :item-pool="analysisResults"
    :loading
    :search-label="t('search')"
    :selected-item="selectedAnalysisResult"
    @select-item="onSelectAnalysisResult"
    @deselect-item="onSelectAnalysisResult(null)"
    @paste="onPasteAnalysisId"
  >
    <template #selected-item-title>
      <span
        data-testid="analysis-result-selector-name"
        class="text-body-1 tw-font-semibold tw-truncate"
      >
        {{ selectedAnalysisResultTitle }}
      </span>
      <v-tooltip
        v-if="showLinkToSelectedResult && (selectedAnalysisResult as AnalyticsResultsForComponentResultIds)?.instance_id"
        location="top"
      >
        <template #activator="{ props: analyticsTooltipProps }">
          <v-btn
            class="ml-2 text-neutral-darken2 tw-border-[rgb(var(--v-theme-neutral-lighten1))] tw-border-solid tw-border analysis-redirect-button"
            data-testid="analysis-redirect-button"
            size="24"
            variant="outlined"
            v-bind="analyticsTooltipProps"
            target="_blank"
            :to="{
              name: 'optimization-selected-component-analyses',
              params: {
                componentInProjectId: componentInProject.id,
                project: appStore.projectId
              },
              query: {
                result: (selectedAnalysisResult as AnalyticsResultsForComponentResultIds)?.result_id ?? selectedAnalysisResultId,
                page: 1,
                instance: (selectedAnalysisResult as AnalyticsResultsForComponentResultIds)?.instance_id
              }
            }"
          >
            <v-icon
              size="14"
              color="neutral-darken3"
            >
              fa:far fa-arrow-up-right
            </v-icon>
          </v-btn>
        </template>
        <span>{{ t('analysis_result_tooltip_text') }}</span>
      </v-tooltip>
    </template>

    <template #item-option="{item: analysisResult}">
      <div class="d-flex flex-row tw-flex-1 tw-justify-between text-body-1">
        <span class="text-subtitle-2 mr-4 text-neutral-darken3">{{ analysisResult.display_name }}</span>
        <span
          :class="[
            `text-${whitelabelColor((analysisResult as AnalyticsResultsForComponentResultIds).signal_color!)}-darken2`,
            'tw-text-right tw-text-wrap'
          ]"
        >{{ analysisResult.interpretation }}</span>
      </div>
    </template>
  </ItemSelectorWithFilter>
</template>

<i18n lang="json" locale="de">
  {
    "analysis_result_tooltip_text": "Analyseergebnis öffnen",
    "search": "Auswählen, suchen oder Analyse-ID einfügen"
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "analysis_result_tooltip_text": "Open analysis result",
    "search": "Select, search or paste analysis ID"
  }
</i18n>

import moment, { Moment } from 'moment'
import i18n from '@/i18n'

export function getDatePartAsString (dateTime: Date|moment.Moment|string): string {
  if (typeof dateTime === 'string') {
    return new Date(dateTime).toISOString().split('T')[0]
  }
  return dateTime.toISOString().split('T')[0]
}

function fixPrefixWordOfDuration (duration: string): string {
  const words = duration.split(' ').slice(-2)
  return `${i18n.global.t('for')} ${words[0]} ${words[1]}`
}

/**
 * Returns the duration of the given date in a humanized format.
 * @param date The duration will be calculated from this date.
 * @returns The duration in a humanized format.
 */
export function getHumanizedDuration (date: Moment): string {
  const now = moment()
  const locale = i18n.global.locale.value
  const duration = moment.duration(now.diff(date))

  moment.locale(locale)

  if (duration.asHours() < 1) {
    return `${i18n.global.t('for')} ${i18n.global.t('recently')}`
  }

  return fixPrefixWordOfDuration(moment(now).add(duration).fromNow(true))
}

/**
 * Returns the duration of the given date in a humanized format.
 * @param startDate The start date of the range.
 * @param endDate The end date of the range.
 * @returns A readable formatted duration.
 */
export function formatDateRange (startDate: string, endDate: string): string {
  const start = moment(startDate)
  const end = moment(endDate)

  const sameDay = start.isSame(end, 'day')
  const sameMonth = start.isSame(end, 'month')
  const sameYear = start.isSame(end, 'year')
  const currentYear = moment().isSame(start, 'year')

  let formattedRange = ''

  if (sameDay) {
    formattedRange = start.format(currentYear ? i18n.global.t('date_ranges.MMM D') : i18n.global.t('date_ranges.YYYY MMM D'))
  } else if (!sameYear) {
    formattedRange = `${start.format(i18n.global.t('date_ranges.YYYY MMM D'))} – ${end.format(i18n.global.t('date_ranges.YYYY MMM D'))}`
  } else if (!sameMonth) {
    formattedRange = `${start.format(i18n.global.t('date_ranges.MMM D'))} – ${end.format(i18n.global.t('date_ranges.MMM D'))}`
  } else {
    const startFormat = currentYear ? i18n.global.t('date_ranges.MMM D') : i18n.global.t('date_ranges.YYYY MMM D')
    const endFormat = i18n.global.locale.value === 'en' ? i18n.global.t('date_ranges.D') : i18n.global.t('date_ranges.MMM D')
    formattedRange = `${start.format(startFormat)} – ${end.format(endFormat)}`
  }

  return formattedRange
}

/*
 * Returns the formatted date in a humanized format.
 * @param date The date to be formatted.
 * @returns The formatted date.
 */
export function readableDate (date: Date): string {
  const locale = i18n.global.locale.value
  moment.locale(locale)
  const now = moment()
  const dateMoment = moment(date)

  if (now.isSame(dateMoment, 'day')) {
    return i18n.global.t('dates.today') as string
  }

  if (now.subtract(1, 'days').isSame(dateMoment, 'day')) {
    return i18n.global.t('dates.yesterday') as string
  }

  if (now.isSame(dateMoment, 'year')) {
    return dateMoment.format(i18n.global.t('date_ranges.MMM D'))
  }

  return dateMoment.format(i18n.global.t('date_ranges.MMM_DD_YYYY'))
}

import { RowData } from '@/components/SummarizedContent/types'
import { defineStore } from 'pinia'
import { computed } from 'vue'
import type { ProjectWithContext, User } from '@aedifion.io/aedifion-api'

import vuexStore from '@/vuex'
import { readableDate } from '@/utils/helpers/dates'
import { fullNameOrEmail } from '@/utils/helpers/user'

export type Keys = 'project' | 'asset_manager' | 'updated' | 'summary'

export const useStatusStore = defineStore('status', () => {
  const projects = computed<ProjectWithContext[]>(() => vuexStore.state.projects.projects)

  const projectRows = computed<RowData<Keys>[]>(() => {
    return projects.value
      .toSorted((projectA: ProjectWithContext, projectB: ProjectWithContext) => {
        if (projectA.project?.status_report_updated_at && projectB.project?.status_report_updated_at) {
          return projectA.project.status_report_updated_at > projectB.project.status_report_updated_at ? -1 : 1
        } else {
          return 0
        }
      })
      .map((project: ProjectWithContext) => {
        return {
          project: {
            custom: {
              data: project.project,
            },
            text: project.project?.name ?? '-',
          },
          asset_manager: {
            text: project.project?.asset_manager ? fullNameOrEmail(project.project?.asset_manager as User) : '-',
          },
          updated: {
            text: project.project?.status_report_updated_at ? readableDate(project.project?.status_report_updated_at) : '-',
          },
          summary: {
            text: project.project?.status_report || '-',
          },
        } as RowData<Keys>
      })
  })

  return {
    projectRows,
  }
})

<script setup lang="ts" generic="C extends { text?: string; id: string }">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import TextEditor from '@/components/TextEditor/TextEditor.vue'
import { type JSONContent } from '@tiptap/vue-3'
import { getJSONForTextEditor } from '@/utils/helpers/getJSONForTextEditor'

const props = withDefaults(defineProps<{
  editedComment?: C|null
}>(), {
  editedComment: null,
})

const emit = defineEmits<{
  'add-comment': [comment: JSONContent]
  'update-comment': [payload: { comment: JSONContent, commentID: string }]
  'cancel-add-comment': []
}>()

const { t } = useI18n()

const transformedComment = computed<JSONContent | null>(() => {
  return props.editedComment ? getJSONForTextEditor(props.editedComment.text ?? '') : null
})

const comment = ref<JSONContent|null>(props.editedComment ? getJSONForTextEditor(props.editedComment.text ?? '') : null)

const isCommentValid = computed(() => {
  if (!comment.value) {
    return false
  }

  if (comment.value.content?.length === 1) {
    const commentContent = comment.value.content[0]?.content

    if (!commentContent || commentContent[0]?.text?.trim() === '' || (commentContent[0]?.text === transformedComment.value?.content?.[0]?.content?.[0]?.text)) {
      return false
    }
  }

  return true
})

const onAddComment = () => {
  if (props.editedComment) {
    emit('update-comment', {
      comment: comment.value!,
      commentID: props.editedComment.id,
    })
    return
  }

  emit('add-comment', comment.value!)
}
</script>

<template>
  <v-form
    data-testid="add-or-update-comment-form"
    @submit.prevent="onAddComment"
  >
    <TextEditor
      v-model="comment"
      class="tw-mb-2"
      data-testid="add-or-update-comment-textarea"
      :placeholder="t('new_comment')"
    />
    <v-card-actions class="pa-0">
      <v-btn
        variant="flat"
        class="flex-grow-1 text-primary d-flex align-center tw-content-center"
        color="primary-lighten3"
        data-testid="add-comment-cancel-button"
        height="40px"
        @click="emit('cancel-add-comment')"
      >
        <span class="tw-leading-5">{{ t('cancel_btn_text') }}</span>
        <v-icon
          end
          size="14"
        >
          fa:far fa-xmark
        </v-icon>
      </v-btn>
      <v-btn
        variant="flat"
        color="primary-darken2"
        class="flex-grow-1 text-white"
        data-testid="add-comment-save-button"
        height="40px"
        :disabled="!isCommentValid"
        type="submit"
      >
        <span class="tw-leading-5">{{ t('save_btn_text') }}</span>
        <v-icon
          end
          size="14"
        >
          fa:far fa-check
        </v-icon>
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<style lang="sass" scoped>
  :deep(.tiptap.ProseMirror p)
    padding: 10px 12px !important
</style>

<i18n lang="json" locale="de">
  {
    "new_comment": "Neuer Kommentar",
    "save_btn_text": "Speichern",
    "cancel_btn_text": "Abbrechen"
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "new_comment": "New Comment",
    "save_btn_text": "Save",
    "cancel_btn_text": "Cancel"
  }
</i18n>

import { User } from '@aedifion.io/aedifion-api'

export function fullNameOrEmail (user: User): string|null {
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`
  } else if (user.email) {
    return user.email
  } else {
    return null
  }
}

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { getSearches, SearchStore, storeSearch } from '@/services/database/search'
import { AnalysisFunction } from '@aedifion.io/aedifion-api'
import { formatValue } from '@/filters/formatting'
import HorizontalStack from '@/components/HorizontalStack.vue'
import { OverviewSummary } from '@/stores/views/Optimization/types'
import SearchInput from '@/components/SearchInput.vue'
import { textForLocale } from '@/utils/helpers/locale'
import { useAnalyticsApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import { useI18n } from 'vue-i18n'

export type ComponentSearchItem = {
  figures: OverviewSummary
  id: number;
  savings: number;
  title: string;
  unit: string;
}

const MAX_HISTORY_ITEMS = 3

const { t } = useI18n()
const analyticsApiStore = useAnalyticsApiStore()

interface PotentialsSearchItem {
  event: 'high' | 'high-and-medium' | 'with-costs-savings'
  text: string
}

interface Props {
  components: Array<ComponentSearchItem>;
  loading: boolean;
}

const props = defineProps<Props>()

/* Menu related */

const menu = ref(false)

function toggleMenu (value = false): void {
  menu.value = value
}

/* Search related */

const search = ref('')

async function emitSearch (): Promise<void> {
  emit('search:search', search.value)
  toggleMenu()
  if (search.value) {
    await storeSearch(SearchStore.SavingsPotential, search.value)
    allSearchItems.value = await getSearches(SearchStore.SavingsPotential)
  }
}

function clearInput (): void {
  search.value = ''
  emit('search:reset')
  toggleMenu(true)
}

function onInput (newValue: string): void {
  search.value = newValue
  toggleMenu(true)
}

/* History Items related */

const allSearchItems = ref<string[]>([])

const historyItems = computed<string[]>(() => {
  return allSearchItems.value
    .filter((searchElement: string) => {
      return searchElement.toLowerCase().startsWith((search.value ?? '').toLowerCase())
    })
    .slice(0, MAX_HISTORY_ITEMS)
})

const hasHistoryItems = computed<boolean>(() => historyItems.value.length > 0)

function selectHistoryItem (item: string): void {
  search.value = item
  emitSearch()
}

/* Analysis function related */

const analysisFunctions = computed<AnalysisFunction[]>(() => {
  if (search.value && analyticsApiStore.functions !== null) {
    return analyticsApiStore.functions.filter((func: AnalysisFunction) => {
      const functionName = textForLocale(func.nameDE, func.nameEN)
      return functionName.toLowerCase().includes(search.value!.toLowerCase())
    })
  } else {
    return []
  }
})

const hasAnalysisFunctions = computed<boolean>(() => analysisFunctions.value.length > 0)

function selectAnalysisFunction (analysisFunction: AnalysisFunction): void {
  emit('search:filter:analysis-function', analysisFunction.id)
  search.value = textForLocale(analysisFunction.nameDE, analysisFunction.nameEN)
  toggleMenu()
}

/* Potentials recommendations related */

const potentialsSearch = computed<PotentialsSearchItem[]>(() => ([
  {
    event: 'high',
    text: t('high_potentials'),
  },
  {
    event: 'high-and-medium',
    text: t('high_and_medium_potentials'),
  },
]))

function selectPotential (potentialsSearch: PotentialsSearchItem): void {
  search.value = potentialsSearch.text
  emit(`search:potentials:${potentialsSearch.event}` as keyof typeof emit)
  toggleMenu()
}

/* Component recommendations related */

const filteredComponents = computed<ComponentSearchItem[]>(() => {
  if (search.value) {
    return props.components.filter((component) => {
      return component.title.toLowerCase().includes(search.value!.toLowerCase())
    })
  } else {
    return props.components
  }
})

const hasComponents = computed<boolean>(() => filteredComponents.value.length > 0)

function selectComponent (component: ComponentSearchItem): void {
  emit('search:filter:component', component.id)
  search.value = component.title
  toggleMenu()
}

const emit = defineEmits<{
  (e: 'search:potentials:high'): void,
  (e: 'search:potentials:high-and-medium'): void,
  (e: 'search:filter:component', id: number): void,
  (e: 'search:filter:analysis-function', id: number): void,
  (e: 'search:search', search: string): void,
  (e: 'search:reset'): void
}>()

onMounted(async () => {
  allSearchItems.value = await getSearches(SearchStore.SavingsPotential)
})
</script>

<template>
  <div
    id="search"
    class="grow"
  >
    <v-menu
      v-model="menu"
      attach="#search"
      location="bottom"
      :close-on-content-click="false"
      :persistent="!(true)"
      content-class="aedifion-box-shadow"
      :disabled="loading"
      max-height="410"
      min-width="500"
    >
      <template
        #activator="{ props: menuProps }"
      >
        <SearchInput
          class="search"
          :disabled="loading"
          :loading="loading"
          :placeholder="t('search_placeholder')"
          :model-value="search"
          v-bind="menuProps"
          @click="toggleMenu(true)"
          @click:clear="clearInput"
          @update:model-value="onInput"
          @keyup.enter="emitSearch"
        />
      </template>
      <v-list>
        <v-list-item
          v-for="item in historyItems"
          :key="`history:${item}`"
          class="d-flex justify-start align-center flex-row"
          data-test-id="search-filter-history-item"
          :title="item"
          @click="selectHistoryItem(item)"
        >
          <template #prepend>
            <v-icon size="14">
              fa:far fa-clock-rotate-left
            </v-icon>
          </template>
        </v-list-item>

        <!-- Analysis Functions as recommendations -->
        <v-list-item
          v-for="analysisFunction in analysisFunctions"
          :key="`analysis:${analysisFunction.id}`"
          class="d-flex justify-start align-center flex-row"
          data-test-id="search-filter-analysis-function-item"
          :title="textForLocale(analysisFunction.nameDE, analysisFunction.nameEN)"
          @click="selectAnalysisFunction(analysisFunction)"
        >
          <template #prepend>
            <v-icon
              size="14"
            >
              fa:far fa-search
            </v-icon>
          </template>
        </v-list-item>
        <v-divider
          v-if="hasHistoryItems || hasAnalysisFunctions"
          class="my-2"
        />

        <!-- Different potentials for optimizations as search recommendations -->
        <v-list-item
          v-for="item in potentialsSearch"
          :key="`potentials:${item.event}`"
          class="d-flex justify-start align-center flex-row"
          data-test-id="search-filter-potentials-item"
          :title="item.text"
          @click="selectPotential(item)"
        >
          <template
            #prepend
          >
            <v-icon
              size="14"
            >
              fa:far fa-search
            </v-icon>
          </template>
        </v-list-item>
        <v-divider
          v-if="hasComponents"
          class="my-2"
        />

        <!-- Components with costs savings and optimization stack for recommendations -->
        <v-list-item
          v-for="component in filteredComponents"
          :key="`component:${component.id}`"
          class="d-flex justify-start align-center flex-row"
          data-test-id="search-filter-component-item"
          @click="selectComponent(component)"
        >
          <template #prepend>
            <v-icon
              class="tw-justify-center"
              size="14"
            >
              fa:far fa-search
            </v-icon>
          </template>
          <v-list-item-title class="d-flex justify-space-between align-center flex-row">
            <span v-text="component.title" />
            <div class="d-flex justify-space-between align-center flex-row">
              <span class="mr-4">{{ formatValue(component.savings, { displayUnit: true, unit: component.unit }) }}</span>
              <HorizontalStack
                :gap-size="4"
                :hoverable="false"
                :items="component.figures"
                :show-figures="false"
                :stack-size="120"
              />
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<style scoped lang="sass">
:deep(.v-list-item__icon)
  color: rgb(var(--v-theme-neutral-darken3)) !important
  min-width: 32px !important
  max-width: 32px !important
  width: 32px !important

:deep(.v-list-item__prepend)
  width: 32px !important
  margin-right: 8px !important
  .v-icon
    color: rgb(var(--v-theme-neutral-darken3)) !important
    min-width: 32px !important
    max-width: 32px !important
    width: 32px !important

:deep(.v-list-item)
  color: rgb(var(--v-theme-neutral-darken3)) !important

  .v-list-item__icon .v-icon
    color: rgb(var(--v-theme-neutral-darken1)) !important

  &:hover
    background-color: rgb(var(--v-theme-primary-lighten4)) !important
    color: rgb(var(--v-theme-primary-darken2)) !important

    .v-list-item__icon .v-icon
      color: rgb(var(--v-theme-primary-darken2)) !important

.search
  width: 320px
</style>

<i18n locale="de">
  {
    "search_placeholder": "Suche um Ergebnisse zu filtern",
    "high_potentials": "Hohe Potentiale",
    "high_and_medium_potentials": "Hohe & mittlere Potentiale",
  }
</i18n>
<i18n locale="en">
  {
    "search_placeholder": "Search to filter results",
    "high_potentials": "High potentials",
    "high_and_medium_potentials": "High & medium potentials",
  }
</i18n>

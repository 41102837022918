<script setup lang="ts">
import { computed } from 'vue'

import SummarizedTable from '@/components/SummarizedTable.vue'
import { ColumnHeader, RowData, Width } from '@/components/SummarizedContent/types'
import { useI18n } from 'vue-i18n'
import { type Keys, useStatusStore } from '@/stores/views/Status'
import { storeToRefs } from '~/pinia/dist/pinia'
import { Project } from '~/@aedifion.io/aedifion-api/build'

const emits = defineEmits<{
  'open-project-details': [Project]
}>()

const { t } = useI18n()
const statusStore = useStatusStore()

const { projectRows } = storeToRefs(statusStore)

const headers = computed<ColumnHeader<Keys>[]>(() => ([
  {
    key: 'project',
    text: t('headers.project'),
    width: Width.MaxContent,
    tooltip: t('headers.project'),
  },
  {
    key: 'asset_manager',
    text: t('headers.asset_manager'),
    width: Width.MaxContent,
    tooltip: t('headers.asset_manager'),
  },
  {
    key: 'updated',
    text: t('headers.updated'),
    width: Width.MaxContent,
    tooltip: t('headers.updated'),
  },
  {
    key: 'summary',
    text: t('headers.summary'),
    width: Width.Grow,
    tooltip: t('headers.summary'),
  },
]))

function showProjectDetails (row: RowData<Keys>) {
  emits('open-project-details', (row.project!.custom.data as Project))
}
</script>

<template>
  <SummarizedTable
    :headers="headers"
    :rows="projectRows"
    :sticky-headers="false"
    selectable
    @select-row="showProjectDetails"
  >
    <template #cell.project="{ row }">
      <div class="tw-font-medium tw-flex tw-justify-start tw-items-center tw-text-sm">
        {{ row.project!.text }}
      </div>
    </template>
    <template #cell.summary="{ row }">
      <span class="three-rows-ellipsis tw-mx-[16px] tw-my-[18px] tw-text-sm tw-font-normal">
        {{ row.summary!.text }}
      </span>
    </template>
  </SummarizedTable>
</template>

<style lang="sass" scoped>
:deep(.body-cell.cell-slot-wrapper:nth-child(4))
  padding-left: 0px !important

.three-rows-ellipsis
  min-height: fit-content
  max-height: calc(3rem + 36px)
  overflow-y: hidden
  text-overflow: ellipsis
  display: -webkit-box
  -webkit-line-clamp: 3
  -webkit-box-orient: vertical
  word-wrap: break-word
  word-break: break-word
</style>

<i18n lang="json" locale="en">
{
  "headers": {
    "project": "Project",
    "asset_manager": "Asset Manager",
    "updated": "Updated",
    "summary": "Summary"
  }
}
</i18n>

<i18n lang="json" locale="de">
  {
    "headers": {
      "project": "Projekt",
      "asset_manager": "Asset Manager",
      "updated": "Aktualisiert",
      "summary": "Zusammenfassung"
    }
  }
  </i18n>

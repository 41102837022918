<script setup lang="ts">
import { ref } from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import StatusTable from './StatusTable.vue'
import Sidepeek from '@/components/Layout/SidePeek.vue'
import { Project } from '@aedifion.io/aedifion-api'
import ProjectStatusForm from './ProjectStatusForm.vue'

const selectedProject = ref<Project|null>(null)

function handleOpenProjectDetails (project: Project) {
  selectedProject.value = project
}

function handleCloseSidepeek () {
  selectedProject.value = null
}
</script>

<template>
  <Sidepeek
    class="tw-h-screen"
    :is-open="selectedProject !== null"
    :ignore-outside-click-on-classes="['status-table']"
    @close="handleCloseSidepeek"
    @outside-click="handleCloseSidepeek"
  >
    <ProjectStatusForm
      v-if="selectedProject"
      :project="selectedProject"
    />
    <template #pageContent>
      <div class="layout-wrapper">
        <PageHeader
          class="page-header"
          title-key="links.meta.title.status"
        />
        <StatusTable
          class="status-table"
          @open-project-details="handleOpenProjectDetails"
        />
      </div>
    </template>
  </Sidepeek>
</template>

<style lang="sass" scoped>
.page-header
  position: relative
  z-index: 1
</style>

<script setup lang="ts">
import { type ColumnHeader, type DefaultSorting, type RowData, SortDirection } from './SummarizedContent/types'
import { computed, onMounted, reactive, ref, useSlots, watch } from 'vue'
import ActionCell from './SummarizedContent/ActionCell.vue'
import Cell from './SummarizedContent/Cell.vue'
import SummarizedContent from '@/components/SummarizedContent/index.vue'
import { useI18n } from 'vue-i18n'

// #region Initializations of Composables and Props
const { locale } = useI18n()

interface Props {
  defaultSorting?: DefaultSorting
  externalSorting?: boolean;
  fallbackCellSpanCount?: number;
  fallbackCellSpanStartIndex?: number;
  headers: ColumnHeader[];
  hideUnitsIfNoValue?: boolean;
  kpiToExpandItsHighestValueRow?: string;
  loadMoreCallback?: () => Promise<void>|void,
  rows: RowData[];
  showHeaders?: boolean;
  stickyHeaders?: boolean;
  summary?: RowData;
  selectable?: boolean;
  resetSelection?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  showHeaders: true,
  stickyHeaders: true,
  externalSorting: false,
  selectable: false,
  resetSelection: true,
})

const emit = defineEmits<{
  (event: 'edit-savings-potential', payload: RowData): void
  (event: 'sort-triggered', payload?: DefaultSorting): void
  (event: 'select-row', payload: RowData): void
}>()

const slots = useSlots()
// #endregion

// #region Expanding the row with the highest value for the given KPI, and expand/collapse functionality
const expandedRows = ref<number[]>([])

onMounted(() => {
  if (props.kpiToExpandItsHighestValueRow) {
    expandHighestValueRowOfKPI(sortedRows.value)
  }
})

function expandHighestValueRowOfKPI (rows: RowData[]): void {
  let index
  const kpi = props.kpiToExpandItsHighestValueRow!
  for (const row of rows) {
    if (row[kpi]?.text && hasChildren(row)) {
      if (index === undefined || row[kpi]!.text > rows[index][kpi]!.text) {
        index = rows.indexOf(row)
      }
    }
  }

  if (index !== undefined) {
    expandedRows.value.push(index)
  }
}

function hasChildren (row: RowData): boolean {
  return 'children' in row && !!row.children?.length
}

function toggleExpansion (row: number): void {
  if (expandedRows.value.includes(row)) {
    expandedRows.value.splice(expandedRows.value.indexOf(row), 1)
  } else {
    expandedRows.value.push(row)
  }
}
// #endregion

// #region Styles of the cells
const columnCount = computed<number>(() => {
  return hasActionColumn.value ? props.headers.length + 1 : props.headers.length
})

const hasActionColumn = computed<boolean>(() => {
  return props.rows.some((row: RowData) => 'children' in row || 'link' in row)
})

const actionsCellCssClasses = computed(() => (rowIndex: number, column: number, siblingsCount: number, parentRowIndex?: number) => {
  const cssClasses = classesForBodyCell(rowIndex, column, siblingsCount).concat('pr-2')
  if (parentRowIndex !== undefined) {
    cssClasses.push('body-cell--child')
  }
  return cssClasses
})

// Except for 'body-cell', these classes are defined in 'components/SummarizedContent/index.vue'
function classesForBodyCell (rowIndex: number, column: number, siblingsCount: number, parentRowIndex?: number, isGrayedOut?: boolean): string[] {
  const isChild = parentRowIndex !== undefined

  const result: string[] = ['bordered']

  if (isChild) {
    result.unshift('body-cell--child')
  } else {
    if (!isGrayedOut) {
      result.unshift('body-cell')
    } else {
      result.unshift('body-cell--grayed-out')
    }
  }

  if (rowIndex === 0 && !isChild) {
    result.push('position-top')
  }

  const isLastOfLevel = rowIndex === siblingsCount - 1
  const isExpanded = expandedRows.value.includes(rowIndex)
  const parentIsLastRow = parentRowIndex === props.rows.length - 1
  const row = sortedRows.value[rowIndex]

  if (isLastOfLevel && (parentIsLastRow || (!isChild && !isExpanded))) {
    result.push('position-bottom')
  }

  if (column === 0) {
    result.push('position-left')
  }

  if (column === columnCount.value - 1) {
    result.push('position-right')
  }

  if (shouldBeFallbackCell(column, row)) {
    result.push('fallback-cell')

    if (doesFallbackCellSpansToTheEndOfTheTable.value && !result.includes('position-right')) {
      result.push('position-right')
    }
  }

  if (props.selectable) {
    result.push('selectable')
  }

  return result
}

const headerKeys = computed<string[]>(() => {
  return props.headers.map((header: ColumnHeader) => header.key)
})

const doesFallbackCellSpansToTheEndOfTheTable = computed<boolean>(() => areFallbackCellSpanPropsSet.value && (columnCount.value === props.fallbackCellSpanStartIndex! + props.fallbackCellSpanCount!))

function spannedColumnsHaveData (row: RowData): boolean {
  return headerKeys.value.filter(key => fallbackCellColumnsKeys.value.includes(key) === true).some((key: string) => row[key]?.text !== undefined)
}

const areFallbackCellSpanPropsSet = computed<boolean>(() => {
  return props.fallbackCellSpanCount !== undefined && props.fallbackCellSpanStartIndex !== undefined
})

const fallbackCellColumnsKeys = computed<string[]>(() => {
  if (!areFallbackCellSpanPropsSet.value) return []

  const fallbackCellColumnsKeys: string[] = []

  for (let i = props.fallbackCellSpanStartIndex!; i < props.fallbackCellSpanStartIndex! + props.fallbackCellSpanCount!; i++) {
    fallbackCellColumnsKeys.push(props.headers[i].key)
  }

  return fallbackCellColumnsKeys
})

function showCellContent (row: RowData, headerKey: string): boolean {
  if (!isCellSlotUsed('fallback')) return true

  return spannedColumnsHaveData(row) || (!fallbackCellColumnsKeys.value.includes(headerKey))
}

function isCellSlotUsed (headerKey: string) {
  return `cell.${headerKey}` in slots
}

function shouldBeFallbackCell (column: number, row: RowData): boolean {
  return isCellSlotUsed('fallback') && column === props.fallbackCellSpanStartIndex && !spannedColumnsHaveData(row)
}
// #endregion

// #region Sorting functionality
const sorting = reactive({
  direction: props.defaultSorting?.direction ?? null as SortDirection|null,
  key: props.defaultSorting?.key ?? null as string|null,
})

const sortedRows = computed<RowData[]>(() => {
  if (sorting.key === null || sorting.direction === null || props.externalSorting) {
    return props.rows
  } else {
    return [...props.rows].sort((left: RowData, right: RowData) => {
      const leftItem = left[sorting.key!]
      const rightItem = right[sorting.key!]
      // Always put rows without content for the sort column below rows with content
      if (leftItem && !rightItem) {
        return -1
      } else if (!leftItem && rightItem) {
        return 1
      } else if (!leftItem && !rightItem) {
        return 0
      } else {
        const comparison = String(leftItem!.text).localeCompare(
          String(rightItem!.text),
          locale.value,
          { numeric: true, sensitivity: 'base' },
        )
        return sorting.direction === SortDirection.ASC ? comparison : (0 - comparison)
      }
    })
  }
})

function resetSort (): void {
  emit('sort-triggered')
  sorting.key = null
  sorting.direction = null
}

function sortBy (key: string, direction: SortDirection): void {
  emit('sort-triggered', {
    key,
    direction,
  })
  sorting.key = key
  sorting.direction = direction
}
// #endregion

const selectedRowIndex = ref<number|null>(null)

const select = (row: RowData, rowIndex: number) => {
  selectedRowIndex.value = rowIndex
  emit('select-row', row)
}

watch(() => props.resetSelection ?? false, (newValue) => {
  if (newValue) {
    selectedRowIndex.value = null
  }
})
</script>

<template>
  <SummarizedContent
    data-testid="summarized-table"
    :headers="headers"
    :load-more-callback="props.loadMoreCallback"
    :show-headers="props.showHeaders"
    :show-action-column="hasActionColumn"
    :sort-by-key="sorting.key"
    :sort-direction="sorting.direction"
    :sticky-headers="props.stickyHeaders"
    :summary="summary"
    @reset-sort="resetSort"
    @sort-by="sortBy"
  >
    <template #body>
      <div
        ref="body"
        class="d-contents"
      >
        <template
          v-for="(row, rowIndex) in sortedRows"
          :key="`body.${rowIndex}`"
        >
          <div
            :data-testid="`row.${rowIndex}`"
            :class="['body-row', selectedRowIndex === rowIndex ? 'selected-row' : '']"
            @click="selectable ? select(row, rowIndex) : undefined"
          >
            <template
              v-for="(header, headerIndex) in headers"
            >
              <div
                v-if="isCellSlotUsed(header.key)"
                :key="`body.cell.${rowIndex}.${headerIndex}`"
                :class="[classesForBodyCell(rowIndex, headerIndex, props.rows.length, undefined, row?.isGrayedOut), 'cell-slot-wrapper']"
              >
                <slot
                  :data="row[header.key]"
                  :header="header"
                  :name="`cell.${header.key}`"
                  :row="row"
                  :row-index="rowIndex"
                />
              </div>

              <Cell
                v-else-if="showCellContent(row, header.key)"
                :key="`body.cell.content.${rowIndex}.${headerIndex}`"
                :class="classesForBodyCell(rowIndex, headerIndex, props.rows.length, undefined, row?.isGrayedOut)"
                :data="row[header.key]"
                :header="header"
                :hide-units-if-no-value="props.hideUnitsIfNoValue"
                :data-testid="`cell.${rowIndex}.${header.key}`"
                :summary-line-formatting="header.forceSummaryFormatting"
              />
              <div
                v-else-if="shouldBeFallbackCell(headerIndex, row)"
                :key="`body.fallback.${rowIndex}.${headerIndex}`"
                :class="[classesForBodyCell(rowIndex, headerIndex, props.rows.length), 'cell-slot-wrapper']"
              >
                <slot
                  name="cell.fallback"
                  :row-index="rowIndex"
                  :header-index="headerIndex"
                  :row="row"
                />
              </div>
            </template>
            <div :class="[classesForBodyCell(rowIndex, columnCount - 1, props.rows.length), 'empty-action-cell', 'pr-2', 'tw-contents']">
              <ActionCell
                v-if="hasActionColumn"
                :class="actionsCellCssClasses(rowIndex, columnCount - 1, props.rows.length)"
                :data="row"
                :data-testid="`action-cell.${rowIndex}`"
                :is-expanded="expandedRows.includes(rowIndex)"
                class="pr-2"
                @toggle-expansion="toggleExpansion(rowIndex)"
              />
            </div>
          </div>
          <template v-if="row.children != undefined && expandedRows.includes(rowIndex)">
            <template
              v-for="(childRow, childRowIndex) in row.children"
              :key="`body.child.${childRowIndex}.${rowIndex}`"
            >
              <div
                :data-testid="`body.child.${childRowIndex}.${rowIndex}`"
                class="body-row"
              >
                <Cell
                  v-for="(header, headerIndex) in headers"
                  :key="`body.child.${childRowIndex}.${headerIndex}.${rowIndex}`"
                  :class="classesForBodyCell(childRowIndex, headerIndex, row.children.length, rowIndex)"
                  :data="childRow[header.key]"
                  :header="header"
                  :data-testid="`body.child.cell.${childRowIndex}.${rowIndex}.${header.key}`"
                  :is-child="true"
                  :summary-line-formatting="header.forceSummaryFormatting"
                />
                <div :class="[classesForBodyCell(childRowIndex, columnCount - 1, row.children.length, rowIndex), 'pr-2', 'tw-contents']">
                  <ActionCell
                    v-if="hasActionColumn"
                    :class="actionsCellCssClasses(rowIndex, columnCount - 1, props.rows.length, rowIndex)"
                    :data="childRow"
                    :is-expanded="expandedRows.includes(childRowIndex)"
                    class="pr-2"
                    @toggle-expansion="toggleExpansion(childRowIndex)"
                  />
                </div>
              </div>
            </template>
          </template>
        </template>
      </div>
    </template>
    <template
      v-for="(_, slot) of $slots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </SummarizedContent>
</template>

<style lang="sass" scoped>
/* This applies the border around the summarized table cells in meter page */
.position-left.bordered
  border-left: 1px solid rgb(var(--v-theme-neutral-lighten1))

.position-right.bordered
  border-right: 1px solid rgb(var(--v-theme-neutral-lighten1))

.position-top.bordered
  border-top: 1px solid rgb(var(--v-theme-neutral-lighten1))

.position-bottom.bordered
  border-bottom: 1px solid rgb(var(--v-theme-neutral-lighten1))

.position-top.position-left
  border-top-left-radius: 4px

.position-top.position-right
  border-top-right-radius: 4px

.position-bottom.position-left
  border-bottom-left-radius: 4px

.position-bottom.position-right
  border-bottom-right-radius: 4px

.body-cell, .body-cell--child, .body-cell--grayed-out
  border-bottom: 1px solid rgb(var(--v-theme-neutral-lighten1))
  height: auto
  min-height: 56px

.body-cell
  background-color: rgb(var(--v-theme-neutral-lighten5))
.body-cell--child, .body-cell--grayed-out
  background-color: rgb(var(--v-theme-neutral-lighten3))

/* This applies the hover effect to all items in a row if one item is hovered. display: contents is required, otherwise the grid will be broken */
.body-row
  display: contents

  &:hover > .body-cell
    & :deep(.unit)
      color: rgb(var(--v-theme-neutral-darken1))

    & :deep(.link-icon)
      display: flex

/* This applies the border around the rows in hover state */
.body-cell.selectable, .body-cell--grayed-out.selectable
  position: relative

.body-row:hover > .body-cell.empty-action-cell.selectable::before, .body-row:hover > .body-cell--grayed-out.empty-action-cell.selectable::before
  content: none

.body-row:hover > .body-cell.position-left.position-top.selectable::before, .body-row:hover > .body-cell--grayed-out.position-left.position-top.selectable::before
  border-top-left-radius: 4px

.body-row:hover > .body-cell.position-left.position-bottom.selectable::before, .body-row:hover > .body-cell--grayed-out.position-left.position-bottom.selectable::before
  border-bottom-left-radius: 4px

.body-row:hover > .body-cell.position-right.position-top.selectable::before, .body-row:hover > .body-cell--grayed-out.position-right.position-top.selectable::before
  border-top-right-radius: 4px

.body-row:hover > .body-cell.position-right.position-bottom.selectable::before, .body-row:hover > .body-cell--grayed-out.position-right.position-bottom.selectable::before
  border-bottom-right-radius: 4px

.body-row:hover > .body-cell.selectable::before, .body-row:hover > .body-cell--grayed-out.selectable::before
  cursor: pointer
  content: ""
  border-top: 1px solid rgb(var(--v-theme-neutral))
  border-bottom: 1px solid rgb(var(--v-theme-neutral))
  position: absolute
  top: -1px
  right: -1px
  bottom: -1px
  left: -1px
  z-index: 2

.body-row:hover > .body-cell.position-left.selectable::before, .body-row:hover > .body-cell--grayed-out.position-left.selectable::before
  border-left: 1px solid rgb(var(--v-theme-neutral))

.body-row:hover > .body-cell.position-right.selectable::before, .body-row:hover > .body-cell--grayed-out.position-right.selectable::before
  border-right: 1px solid rgb(var(--v-theme-neutral))

/* This applies the border around the rows in press state */
.body-row:active > .body-cell.position-left.position-top.selectable::before, .body-row:active > .body-cell--grayed-out.position-left.position-top.selectable::before
  border-top-left-radius: 4px

.body-row:active > .body-cell.position-left.position-bottom.selectable::before, .body-row:active > .body-cell--grayed-out.position-left.position-bottom.selectable::before
  border-bottom-left-radius: 4px

.body-row:active > .body-cell.position-right.position-top.selectable::before, .body-row:active > .body-cell--grayed-out.position-right.position-top.selectable::before
  border-top-right-radius: 4px

.body-row:active > .body-cell.position-right.position-bottom.selectable::before, .body-row:active > .body-cell--grayed-out.position-right.position-bottom.selectable::before
  border-bottom-right-radius: 4px

.body-row:active > .body-cell.selectable::before, .body-row:active > .body-cell--grayed-out.selectable::before
  content: ""
  border-top: 1px solid rgb(var(--v-theme-neutral))
  border-bottom: 1px solid rgb(var(--v-theme-neutral))
  position: absolute
  top: -1px
  right: -1px
  bottom: -1px
  left: -1px
  z-index: 3

.body-row:active > .body-cell.position-left.selectable::before, .body-row:active > .body-cell--grayed-out.position-left.selectable::before
  border-left: 1px solid rgb(var(--v-theme-neutral))

.body-row:active > .body-cell.position-right.selectable::before, .body-row:active > .body-cell--grayed-out.position-right.selectable::before
  border-right: 1px solid rgb(var(--v-theme-neutral))

.body-row:active > .body-cell.empty-action-cell.selectable::before, .body-row:active > .body-cell--grayed-out.empty-action-cell.selectable::before
  content: none

/* This applies as the shadow around the rows in press state */
.body-row:active > .body-cell.position-left.position-top.selectable::after, .body-row:active > .body-cell--grayed-out.position-left.position-top.selectable::after
  border-top-left-radius: 7px

.body-row:active > .body-cell.position-left.position-bottom.selectable::after, .body-row:active > .body-cell--grayed-out.position-left.position-bottom.selectable::after
  border-bottom-left-radius: 7px

.body-row:active > .body-cell.position-right.position-top.selectable::after, .body-row:active > .body-cell--grayed-out.position-right.position-top.selectable::after
  border-top-right-radius: 7px

.body-row:active > .body-cell.position-right.position-bottom.selectable::after, .body-row:active > .body-cell--grayed-out.position-right.position-bottom.selectable::after
  border-bottom-right-radius: 7px

.body-row:active > .body-cell.selectable::after, .body-row:active > .body-cell--grayed-out.selectable::after
  content: ""
  border-top: 4px solid rgba(var(--v-theme-neutral-darken4), 0.12)
  border-bottom: 4px solid rgba(var(--v-theme-neutral-darken4), 0.12)
  position: absolute
  top: -4px
  right: 4px
  bottom: -4px
  left: -4px
  z-index: 3

.body-row:active > .body-cell.position-left.selectable::after, .body-row:active > .body-cell--grayed-out.position-left.selectable::after
  border-left: 4px solid rgba(var(--v-theme-neutral-darken4), 0.12)

.body-row:active > .body-cell.position-right.selectable::after, .body-row:active > .body-cell--grayed-out.position-right.selectable::after
  border-right: 4px solid rgba(var(--v-theme-neutral-darken4), 0.12)
  right: -4px

.body-row:active > .body-cell.empty-action-cell.selectable::after, .body-row:active > .body-cell--grayed-out.empty-action-cell.selectable::after
  content: none

/* This applies as the shadow around the rows in selected state */
.selected-row > .body-cell.position-left.position-top.selectable::after, .selected-row > .body-cell--grayed-out.position-left.position-top.selectable::after
  border-top-left-radius: 7px

.selected-row > .body-cell.position-left.position-bottom.selectable::after, .selected-row > .body-cell--grayed-out.position-left.position-bottom.selectable::after
  border-bottom-left-radius: 7px

.selected-row > .body-cell.position-right.position-top.selectable::after, .selected-row > .body-cell--grayed-out.position-right.position-top.selectable::after
  border-top-right-radius: 7px

.selected-row > .body-cell.position-right.position-bottom.selectable::after, .selected-row > .body-cell--grayed-out.position-right.position-bottom.selectable::after
  border-bottom-right-radius: 7px

.selected-row > .body-cell.selectable::after, .selected-row > .body-cell--grayed-out.selectable::after
  content: ""
  border-top: 4px solid rgba(var(--v-theme-neutral-darken4), 0.12)
  border-bottom: 4px solid rgba(var(--v-theme-neutral-darken4), 0.12)
  position: absolute
  top: -4px
  right: 4px
  bottom: -4px
  left: -4px
  z-index: 4

.selected-row > .body-cell.position-left.selectable::after, .selected-row > .body-cell--grayed-out.position-left.selectable::after
  border-left: 4px solid rgba(var(--v-theme-neutral-darken4), 0.12)

.selected-row > .body-cell.position-right.selectable::after, .selected-row > .body-cell--grayed-out.position-right.selectable::after
  border-right: 4px solid rgba(var(--v-theme-neutral-darken4), 0.12)
  right: -4px

/* This applies the border around the rows in selected state */
.selected-row > .body-cell.position-left.position-top.selectable::before, .selected-row > .body-cell--grayed-out.position-left.position-top.selectable::before
  border-top-left-radius: 4px

.selected-row > .body-cell.position-left.position-bottom.selectable::before, .selected-row > .body-cell--grayed-out.position-left.position-bottom.selectable::before
  border-bottom-left-radius: 4px

.selected-row > .body-cell.position-right.position-top.selectable::before, .selected-row > .body-cell--grayed-out.position-right.position-top.selectable::before
  border-top-right-radius: 4px

.selected-row > .body-cell.position-right.position-bottom.selectable::before, .selected-row > .body-cell--grayed-out.position-right.position-bottom.selectable::before
  border-bottom-right-radius: 4px

.selected-row > .body-cell.selectable::before, .selected-row > .body-cell--grayed-out.selectable::before
  content: ""
  border-top: 1px solid rgb(var(--v-theme-neutral-darken2))
  border-bottom: 1px solid rgb(var(--v-theme-neutral-darken2))
  position: absolute
  top: -1px
  right: 1px
  bottom: -1px
  left: -1px
  z-index: 4

.selected-row > .body-cell.position-left.selectable::before, .selected-row > .body-cell--grayed-out.position-left.selectable::before
  border-left: 1px solid rgb(var(--v-theme-neutral-darken2))

.selected-row > .body-cell.position-right.selectable::before, .selected-row > .body-cell--grayed-out.position-right.selectable::before
  border-right: 1px solid rgb(var(--v-theme-neutral-darken2))

/* This should be added so as to prevent hover border affecting on the selected-hover state */
.selected-row:hover > .body-cell.selectable::before, .selected-row:hover > .body-cell--grayed-out.selectable::before
  content: ""
  border-top: 1px solid rgb(var(--v-theme-neutral-darken2))
  border-bottom: 1px solid rgb(var(--v-theme-neutral-darken2))
  position: absolute
  top: -1px
  right: 1px
  bottom: -1px
  left: -1px
  z-index: 4

.selected-row:hover > .body-cell.position-left.selectable::before, .selected-row:hover > .body-cell--grayed-out.position-left.selectable::before
  border-left: 1px solid rgb(var(--v-theme-neutral-darken2))

.selected-row:hover > .body-cell.position-right.selectable::before, .selected-row:hover > .body-cell--grayed-out.position-right.selectable::before
  border-right: 1px solid rgb(var(--v-theme-neutral-darken2))

.selected-row > .body-cell.empty-action-cell.selectable::before, .selected-row > .body-cell--grayed-out.empty-action-cell.selectable::before
  content: none

.selected-row > .body-cell.empty-action-cell.selectable::after, .selected-row > .body-cell--grayed-out.empty-action-cell.selectable::after
  content: none

.cell-slot-wrapper
  display: flex
  padding: 0 16px !important

.fallback-cell
  grid-column: span v-bind(fallbackCellSpanCount)
</style>

<script setup lang="ts">
import { computed, reactive } from 'vue'
import UserSelector from '../Optimization/Checklist/UserSelector.vue'
import { type Project, ShortUser } from '@aedifion.io/aedifion-api'
import TextEditor from '@/components/TextEditor/TextEditor.vue'
import Comments from '@/components/Comments/CommentsList.vue'

import { useI18n } from 'vue-i18n'
import { useDate } from 'vuetify'
import { getJSONForTextEditor } from '@/utils/helpers/getJSONForTextEditor'
import { type JSONContent } from '@tiptap/vue-3'

interface Props {
  project: Project
}

const props = defineProps<Props>()

const { t } = useI18n()
const date = useDate()

const formState = reactive<{
  asset_manager: ShortUser|null,
  start: Date|null
  status_report_updated_at: Date|null
  status_report: string
}>({
  asset_manager: props.project.asset_manager as ShortUser ?? null,
  start: props.project.start ?? null,
  status_report_updated_at: props.project.status_report_updated_at ?? null,
  status_report: props.project.status_report ?? '',
})

const summaryData = computed<JSONContent|null>({
  get: () => {
    if (props.project.status_report === undefined) {
      return null
    }

    return getJSONForTextEditor(formState.status_report)
  },
  set: (value) => {
    formState.status_report = JSON.stringify(value)
  },
})

function handleUpdateProjectStart (value: Date|null) {
  formState.start = value
  handleUpdateProject()
}

function handleUpdateProjectUpdated (value: Date|null) {
  formState.status_report_updated_at = value
  handleUpdateProject()
}

function handleUpdateProject () {
  // eslint-disable-next-line no-console
  console.debug('Updating project:', formState)
}

// #region Comments

async function handleAddComment (newComment: JSONContent) {
  // eslint-disable-next-line no-console
  console.debug('Adding comment:', newComment)
}

async function handleUpdateComment (updatedComment: { comment: JSONContent; commentID: string }) {
  // eslint-disable-next-line no-console
  console.debug('Updating comment:', updatedComment)
}

async function handleDeleteComment (commentID: string) {
  // eslint-disable-next-line no-console
  console.debug('Deleting comment:', commentID)
}
</script>

<template>
  <div class="tw-px-4 tw-py-6">
    <span class="tw-px-3 tw-font-semibold tw-text-xl">{{ project?.name }}</span>
    <v-container class="tw-flex tw-flex-col tw-gap-[14px]">
      <v-row class="table-row">
        <v-col class="property-name d-flex align-center">
          <v-icon
            size="14"
            class="mr-3"
            color="neutral-darken1"
          >
            far fa-user-circle
          </v-icon>
          <v-tooltip
            content-class="aedifion-tooltip"
            location="left"
          >
            <template
              #activator="{ props:assigneeTooltip }"
            >
              <span
                v-bind="assigneeTooltip"
                class="text-body-1 text-neutral-darken1 d-block text-truncate"
                v-text="t('form_fields.asset_manager')"
              />
            </template>
            <span
              v-text="t('form_fields.asset_manager')"
            />
          </v-tooltip>
        </v-col>
        <v-col
          class="text-neutral-darken4 property-value"
        >
          <UserSelector
            :model-value="formState.asset_manager"
            :is-edit-mode="false"
            :is-read-only="true"
          />
        </v-col>
      </v-row>

      <v-row class="table-row">
        <v-col class="property-name">
          <v-icon
            size="14"
            class="mr-3"
            color="neutral-darken1"
          >
            far fa-calendar-check
          </v-icon>
          <span class="text-body-1 text-neutral-darken1">{{ t('form_fields.project_start') }}</span>
        </v-col>
        <v-col class="text-neutral-darken4 tw-font-semibold property-value">
          <v-menu
            :offset="[1, 0]"
            :close-on-content-click="false"
          >
            <template #activator="{ props: startDateProps }">
              <v-text-field
                :model-value="formState.start ? date.format(formState.start, 'normalDateWithWeekday') : undefined"
                hide-details
                single-line
                readonly
                disabled
                data-testid="start-date"
                density="compact"
                :label="t('labels.enter_date')"
                variant="plain"
                class="mt-0 pt-0 custom-input body-1-text no-decoration"
                v-bind="startDateProps"
              />
            </template>
            <v-date-picker
              :model-value="formState.start"
              width="auto"
              @update:model-value="handleUpdateProjectStart"
            />
          </v-menu>
        </v-col>
      </v-row>

      <v-row class="table-row">
        <v-col class="property-name">
          <v-icon
            size="14"
            class="mr-3"
            color="neutral-darken1"
          >
            far fa-sparkles
          </v-icon>
          <span class="text-body-1 text-neutral-darken1">{{ t('form_fields.updated') }}</span>
        </v-col>
        <v-col class="text-neutral-darken4 tw-font-semibold property-value">
          <v-menu
            :offset="[1, 0]"
            :close-on-content-click="false"
          >
            <template #activator="{ props: startDateProps }">
              <v-text-field
                :model-value="formState.status_report_updated_at ? date.format(formState.status_report_updated_at, 'normalDateWithWeekday') : undefined"
                hide-details
                single-line
                readonly
                disabled
                data-testid="start-date"
                density="compact"
                :label="t('labels.enter_date')"
                variant="plain"
                class="mt-0 pt-0 custom-input body-1-text no-decoration"
                v-bind="startDateProps"
              />
            </template>
            <v-date-picker
              :model-value="formState.start"
              width="auto"
              @update:model-value="handleUpdateProjectUpdated"
            />
          </v-menu>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="tw-mt-3">
      <TextEditor
        key="project-details"
        v-model="summaryData"
        disabled
        :placeholder="t('labels.enter_description')"
        @blur="handleUpdateProject"
      />
    </v-container>
  </div>

  <v-divider
    class="border-opacity-100"
  />

  <div class="tw-px-4 tw-py-6">
    <Comments
      :comments="[]"
      :loading="false"
      @add-comment="handleAddComment"
      @update-comment="handleUpdateComment"
      @delete-comment="handleDeleteComment"
    />
  </div>
</template>

<style lang="sass" scoped>
  .body-1-text
    :deep(.v-field)
      input
        font-size: .875rem !important
        line-height: 1.25rem
      &:not(.v-field--focused)
        font-weight: 600

  .custom-input
    margin-bottom: 1px !important
    :deep(.v-field__input, textarea):not(.v-textarea__sizer)
      height: fit-content
      padding: 8px !important
      z-index: 100 !important
    &:not(.v-textarea)
      :deep(.v-field__input)
        padding: 0 8px !important
        height: 40px !important
    :deep(.v-field)
      border: 1px solid transparent !important
      &:hover:not(.v-field--focused)
        background-color: rgb(var(--v-theme-neutral-lighten2)) !important
        border-radius: 4px !important
    :deep(.v-field--focused)
      border-color: rgb(var(--v-theme-neutral-darken2)) !important
      border-radius: 4px !important
      font-weight: 400 !important
    :deep(.v-label)
      text-decoration: underline !important
      padding: 0 8px !important
      color: rgb(var(--v-theme-neutral-darken3)) !important
      opacity: 1
      font-weight: 600 !important

  .no-decoration
    :deep(.v-label)
      text-decoration: none !important

  .table-row
    @apply tw-flex-nowrap tw-max-h-[40px] tw-items-center tw-mt-0 tw-whitespace-nowrap

  .property-name
    @apply tw-max-w-[160px] tw-leading-5 tw-py-0 tw-h-[40px] tw-content-center tw-overflow-hidden

  .property-value
    @apply tw-py-0 tw-h-[40px] tw-content-center
</style>

<i18n locale="en">
{
  "form_fields": {
    "asset_manager": "Asset Manager",
    "project_start": "Project Start",
    "updated": "Updated"
  },
  "labels": {
    "enter_date": "Enter Date",
    "enter_description": "Enter summary"
  }
}
</i18n>

<i18n locale="de">
{
  "form_fields": {
    "asset_manager": "Asset Manager",
    "project_start": "Projektstart",
    "updated": "Aktualisiert"
  },
  "labels": {
    "enter_date": "Datum eingeben",
    "enter_description": "Zusammenfassung tippen"
  }
}
</i18n>
